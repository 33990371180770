import React, { Fragment, useEffect, useState } from "react";
import '../styles/PaymentSuccess.css';
import { verifyPayment } from "../utils";

const PaymentSuccess = () => {
    const [orderDetails, setOrderDetails] = useState(null); // Changed initial state to null
    const [loading, setLoading] = useState(true); // To track loading state

    const handleDownload = (URL) => {
        const fileUrl = URL; // The URL of the file to download
        const link = document.createElement("a"); // Create a new anchor element
        link.href = fileUrl; // Set the href attribute to the file URL
        link.download = ''; // Optionally set a download attribute to specify the filename (blank will use the default filename from the URL)
        link.click(); // Simulate a click to trigger the download
    };

    // Function to fetch order information
    const fetchOrderInformation = async (orderId) => {
        try {
            const orderDetails = await verifyPayment(orderId);
            setOrderDetails(orderDetails); // Store the fetched details in state
            setLoading(false); // Set loading to false
        } catch (error) {
            console.error("Error fetching order information:", error);
            setLoading(false); // Stop loading if there's an error
        }
    };

    useEffect(() => {
        const orderId = window.location.pathname.split("/payment-success/")[1]; // Get order ID from the URL

        if (orderId && !orderDetails) { // Only fetch if not already fetched
            fetchOrderInformation(orderId);
        }

    }, [orderDetails]); // Dependency array ensures the effect runs when `orderDetails` changes

    // Check if orderDetails is available
    const { status:paymentStatus, purchase_units } = orderDetails || {};

    // If loading, show a loading state
    if (loading) {
        return <div>Loading...</div>;
    }

    // Extract product information
    const { items, amount} = purchase_units[0] || {};
    const { name: productName, description: productDescription, image_url: imageUrl } = items[0] || {};
    const { value: totalAmount } = amount || {};
    
    // For demonstration, use placeholder URL if not available
    const productImage = imageUrl || "https://via.placeholder.com/150";

    return (
        orderDetails?.status ? (
            <div className="payment-success-container">
                {paymentStatus === "COMPLETED" ? (
                    <Fragment>
                        <h2 className="success-title">Payment Successful!</h2>
                        <p className="welcome-note">Thank you for your purchase. We appreciate your business.</p>
                        <div className="product-details-success">
                            <img
                                src={productImage}
                                alt="Product"
                                className="product-image-success"
                            />
                            <div className="product-info">
                                <h3 className="product-title">{productName}</h3>
                                <p className="product-description" dangerouslySetInnerHTML={{ __html: productDescription }} />
                                <p className="product-amount">Total: ${totalAmount}</p>
                            </div>
                        </div>

                        {/* Download button */}
                        <button className="download-btn" onClick={() => handleDownload(orderDetails?.URL || "")}>
                            <span>Download</span>
                        </button>
                    </Fragment>
                ) : (
                    <Fragment>
                        <h2 className="failure-title">Payment Failed!</h2>
                        <p className="failure-note">Unfortunately, your payment could not be processed. Please try again.</p>

                        <div className="failure-details">
                            <img
                                src={productImage}
                                alt="Failure"
                                className="failure-image"
                            />
                            <div className="failure-info">
                                <h3 className="failure-title">{productName}</h3>
                                <p className="product-description" dangerouslySetInnerHTML={{ __html: productDescription }} />
                            </div>
                        </div>

                        <button className="retry-btn" onClick={() => window.location.reload()}>
                            <span>Try Again</span>
                        </button>
                    </Fragment>
                )}
            </div>
        ) : null
    );
};

export default PaymentSuccess;
