import React from 'react';
import '../styles/FAQ.css';
import MetaTags from '../components/MetaTags';

const WarrantyPolicy = () => {
  return (
    <div className="faq-container">
      <h1>Warranty Policy</h1>

      <MetaTags
        title={`Warranty Policy - Placement Master`}
        description={`Understand the warranty policy for our digital products at Placement Master. Find details about support, replacement, and more.`}
        images={`https://placementmaster.in/assets/img/logo.png`}
        url={`https://shop.onlinedevtools.in/warranty-policy`}
      />

      <div className="faq-content">
        <div className="faq-text">
          <p>
            At <strong>Placement Master</strong>, we strive to provide high-quality digital products to meet your expectations. Below is our comprehensive warranty policy to ensure your satisfaction.
          </p>

          <h2>Scope of Warranty</h2>
          <p>
            Our warranty covers digital products purchased directly from Placement Master. The warranty ensures that the product is functional and free from defects at the time of delivery.
          </p>

          <h2>Warranty Limitations</h2>
          <p>
            Please note the following:
          </p>
          <ul>
            <li>The warranty applies only to issues related to product access or functionality.</li>
            <li>The warranty does not cover misuse, unauthorized modifications, or issues arising due to technical incompatibilities on your end.</li>
          </ul>

          <h2>Claiming Warranty</h2>
          <p>
            If you encounter any issues, please reach out to our support team at <strong>support@placementmaster.in</strong> with:
          </p>
          <ul>
            <li>Your order ID.</li>
            <li>A description of the issue.</li>
            <li>Any supporting screenshots or videos.</li>
          </ul>

          <h2>Resolution</h2>
          <p>
            Upon verifying your claim, we will take one of the following actions:
          </p>
          <ul>
            <li>Provide access to the product again.</li>
            <li>Offer a replacement product.</li>
            <li>Suggest alternative solutions as deemed appropriate.</li>
          </ul>

          <h2>Important Notes</h2>
          <p>
            - Refunds are not covered under this policy; refer to our <a href="/return-exchange">Refund Policy</a> for details.
            <br />
            - For digital products, the warranty is valid for 7 days from the date of purchase.
          </p>
        </div>

        <div className="faq-images">
          <img
            src={'https://cdn.shopify.com/s/files/1/0590/7873/8097/files/signing-shipping-forms-for-large-box_720x.jpg?v=1628863960'}
            alt="Warranty"
            className="faq-image"
          />
        </div>
      </div>
    </div>
  );
};

export default WarrantyPolicy;
