// MetaTags.js
import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({ title, description, images, url }) => {
  return (
    <Helmet>
      <title>{title} - Placement Master Hub</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={images} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="Placement Master" />
      <link rel="canonical" href={url}/>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={images} />
      <meta name="twitter:url" content={url} />
    </Helmet>
  );
};

export default MetaTags;
