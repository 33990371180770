import axios from "axios";

import { CONFIG } from "./config"

export const fetchAndTransformProductListing = async () => {
    const listingResponse = await axios.get(
        `https://raw.githubusercontent.com/stephenjesus/products/main/productListing.json`
    );
    return listingResponse.data.map((product, index) => ({
        id: index + 1,
        name: product.name,
        title: `${product.name} Placement Papers`,
        discountPrice: 149,
        price: 299,
        image: product.image,
        category: "MNC",
        path: `question-paper${product.path}`,
    }));
};


const CLIENT_ID = CONFIG.REACT_APP_PAYPAL_CLIENT_ID

const SECRET = CONFIG.REACT_APP_PAYPAL_KEY

export const fetchPaypalToken = async (CLIENT_ID, SECRET) => {

    try {
        const response = await fetch(`${CONFIG.PAYPAL_BASE_URL}/v1/oauth2/token`, {
            method: "POST",
            headers: {
                Authorization: `Basic ${btoa(`${CLIENT_ID}:${SECRET}`)}`,
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: "grant_type=client_credentials",
        });

        if (!response.ok) {
            throw new Error("Failed to fetch PayPal access token");
        }

        const data = await response.json();

        return data.access_token;
    } catch (error) {
        console.log(error);
    }

}

export const verifyPayment = async (orderId) => {

    const accessToken = await fetchPaypalToken(CLIENT_ID, SECRET);

    const response = await fetch(`${CONFIG.PAYPAL_BASE_URL}/v2/checkout/orders/${orderId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        throw new Error("Failed to verify payment");
    }

    const orderDetails = await response.json();

    return orderDetails;
};
