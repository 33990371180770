import React from "react";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { verifyPayment } from "../utils"

const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID

const PayPalPaymentButton = (props = {}) => {

    const { title, description, discountPrice, id, image } = props.payload || {}

    return (
        <PayPalScriptProvider options={{ "client-id": CLIENT_ID, currency: "USD" }}>
            <div>
                <PayPalButtons
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        value: Number(discountPrice), // Replace with the amount for your product
                                        breakdown: {
                                            item_total: {
                                                currency_code: "USD",
                                                value: Number(discountPrice), // Item total breakdown (should match total value)
                                            },
                                        },
                                    },
                                    items: [
                                        {
                                            name: title,
                                            unit_amount: {
                                                "currency_code": "USD",
                                                "value":  Number(discountPrice)
                                            },
                                            quantity: 1,
                                            category: "DIGITAL_GOODS",
                                            image_url: image,
                                            id,
                                        }
                                    ],
                                }
                            ],
                            note_to_payer: description,
                            custom: description,
                            application_context: {
                                shipping_preference: "NO_SHIPPING", // Disable shipping address
                                user_action: "PAY_NOW",
                            }
                        });
                    }}
                    onApprove={async (data, actions) => {

                        try {
                            const details = await actions.order.capture();

                            const verificationDetails = await verifyPayment(data.orderID);

                            if (verificationDetails.id) {
                                window.location.href = `${window.location.origin}/payment-success/${verificationDetails.id}`;
                            }
                            return details;
                        } catch (error) {
                            console.error("Verification Failed:", error);
                        }

                    }}
                    onCancel={() => {
                        console.log("Payment Canceled");
                    }}
                />
            </div>
        </PayPalScriptProvider>
    );
};

export default PayPalPaymentButton;
