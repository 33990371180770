import React from 'react';
import { Box, Typography, Button, Card, CardContent, CardMedia, Grid } from '@mui/material';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';

// Define reusable styles for consistency and maintainability
const cardStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '16px',
  boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  background: '#ffffff',
  padding: '24px',
  maxWidth: '380px',
  margin: '0 auto',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 12px 30px rgba(0, 0, 0, 0.2)',
  },
};

const buttonStyles = {
  background: 'linear-gradient(135deg, #f58020, #ff7b26)',
  color: '#fff',
  borderRadius: '50px',
  fontSize: '16px',
  padding: '10px 24px',
  display: 'flex',
  alignItems: 'center',
  transition: 'background 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(135deg, #ff7b26, #f58020)',
  },
};

const mediaStyles = {
  borderRadius: '12px',
  objectFit: 'contain',
  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
};

const textStyles = {
  title: { fontWeight: 600, fontSize: { xs: '1.5rem', sm: '1.75rem' } },
  description: { fontSize: { xs: '0.9rem', sm: '1rem' } },
  price: { fontWeight: 'bold', marginBottom: '16px', fontSize: { xs: '1.2rem', sm: '1.5rem' } },
};

const SingleProductHighlight = ({ product }) => {
  return (
    <Box
      sx={{
        padding: { xs: '2rem', sm: '4rem 2rem' },
        backgroundColor: '#f9f9f9',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Card sx={cardStyles}>
            <CardMedia component="img" alt={product.name} height="280" image={product.image} title={product.name} sx={mediaStyles} />
            <CardContent sx={{ textAlign: 'center', padding: '16px' }}>
              <Typography variant="h5" gutterBottom sx={textStyles.title}>
                {product.name}
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph sx={textStyles.description}>
                {product.description}
              </Typography>
              <Typography variant="h6" color="primary" sx={textStyles.price}>
                ${product.price}
              </Typography>
              <Button
                variant="contained"
                startIcon={<ArrowForwardIcon />}
                href={product.link}
                target="_blank"
                sx={{
                  ...buttonStyles,
                  fontSize: { xs: '14px', sm: '16px' },
                  padding: { xs: '8px 16px', sm: '10px 24px' },
                }}
              >
                Download Now
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleProductHighlight;
