import React from 'react';
import '../styles/About.css';
import MetaTags from '../components/MetaTags';

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <h1>About Us</h1>

      <MetaTags
        title={`About Us - Placement Master Hub`}
        description={`Placement Master Hub helps students prepare for placement exams and certifications in the USA, including SAT, ACT, GRE, GMAT, LSAT, and more.`}
        images={`https://placementmaster.in/assets/img/logo.png`}
        url={`https://shop.onlinedevtools.in/about`}
      />

      <div className="about-us-content">
        <div className="about-us-text">
          <p>
            At <strong>Placement Master Hub</strong>, we are committed to helping students succeed in their education and professional journeys by preparing them for major exams like SAT, ACT, GRE, GMAT, LSAT, and more.
          </p>

          <p>
            Our platform offers comprehensive resources for students aiming to ace college admissions tests, graduate school entrance exams, and professional certification exams. Whether you're planning to study in the USA or advance your career with certifications, <strong>Placement Master Hub</strong> has the tools to help you succeed.
          </p>

          <h2>Our Vision</h2>
          <p>
            We believe that every student has the potential to succeed. Our mission is to provide the best preparation materials for exams like SAT, GRE, GMAT, and LSAT, ensuring that you are ready for the challenges ahead. Whether you are an aspiring undergraduate, graduate, or professional, our resources are designed to help you reach your goals.
          </p>

          <p>
            Many of our users have successfully secured positions in top universities and companies across the globe, thanks to our comprehensive study materials and practice exams.
          </p>

          <h3>Join Us</h3>
          <p>
            Become part of the <strong>Placement Master Hub</strong> community to access high-quality resources, get updates on exam schedules, practice papers, and receive expert tips to help you prepare for your upcoming exams. Your success is just a step away!
          </p>

          <p className="contact-info">
            For more information, feel free to contact us at: 📧 <strong>support@placementmaster.in</strong>
          </p>
        </div>
      </div>

      <div className="about-us-images">
        <img
          src={'https://cdn.shopify.com/s/files/1/0590/7873/8097/files/collecting-order-at-curbside-from-retail-store_3750x.jpg?v=1628523830'}
          alt="Team working on exam preparation"
          className="about-us-image"
        />
      </div>
    </div>
  );
};

export default AboutUs;
