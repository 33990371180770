const CONFIG = {
    BACKEND_URL: "https://pm-backend-0jc4.onrender.com",
    RAZORPAY_KEY_ID: "rzp_test_TQV9qqM4mQbq82",
    RAZORPAY_SCRIPT: "https://checkout.razorpay.com/v1/checkout.js",
    PAYPAL_BASE_URL: "https://api-m.sandbox.paypal.com",
    REACT_APP_PAYPAL_CLIENT_ID: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    REACT_APP_PAYPAL_KEY: process.env.REACT_APP_PAYPAL_KEY
}

// const PRODUCTION_CONFIG = {
//     BACKEND_URL: "https://pm-backend-0jc4.onrender.com",
//     RAZORPAY_KEY_ID: "rzp_live_PM74vFGDJekjUp",
//     RAZORPAY_SCRIPT: "https://checkout.razorpay.com/v1/checkout.js",
//     PAYPAL_BASE_URL: "https://api-m.paypal.com",
//     REACT_APP_PAYPAL_CLIENT_ID: process.env.REACT_APP_PAYPAL_CLIENT_ID,
//     REACT_APP_PAYPAL_KEY: process.env.REACT_APP_PAYPAL_KEY
// }

module.exports = { CONFIG: CONFIG }