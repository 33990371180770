import React from 'react';
import '../styles/ProductPage.css'; // Import CSS file
import PayPalButton from "./PayPalButton"

const ProductDetails = ({ title, description, tags, details, discountPrice, originalPrice, id, image }) => {

    return (
        <div className="product-details">
            <h1>{title}</h1>
            <p className="product-description" dangerouslySetInnerHTML={{ __html: description }} />
            <div className="product-pricing">
                <span className="discount-price">${discountPrice}</span>
                <span className="original-price">${originalPrice}</span>
            </div>

            <div className="product-tags">
                {tags.map((tag, index) => (
                    <span key={index} className="product-tag">{tag}</span>
                ))}
            </div>

            {/* Buy Button */}
            <PayPalButton payload={{ title, description, tags, details, discountPrice, originalPrice, id, image }} />

            <div className="product-details-info">
                <p dangerouslySetInnerHTML={{ __html: details }} />
            </div>
        </div>
    );
};

export default ProductDetails;
