import React from 'react';
import '../styles/PrivacyPolicy.css';
import MetaTags from '../components/MetaTags';

const TermsAndConditions = () => {
  return (
    <div className="privacy-container">
      <MetaTags
        title={`Terms and Conditions - Placement Master`}
        description={`These Terms and Conditions govern your use of Placement Master’s website and services. By using our platform, you agree to comply with the following terms. Please review them carefully.`}
        images={`https://placementmaster.in/assets/img/logo.png`}
        url={`https://shop.onlinedevtools.in/terms-conditions`}
      />
      <h1>Terms and Conditions</h1>

      <p>
        These Terms and Conditions, along with the Privacy Policy or other terms (“Terms”), constitute a binding agreement by and between 
        <strong>PLACEMENT MASTER TECH (OPC) PRIVATE LIMITED</strong> (“Website Owner,” “we,” “us,” or “our”) and you (“you” or “your”) 
        and relate to your use of our website, goods (as applicable), or services (as applicable) (collectively, “Services”).
      </p>

      <p>
        By using our website and availing the Services, you agree that you have read and accepted these Terms (including the Privacy Policy). 
        We reserve the right to modify these Terms at any time and without assigning any reason. It is your responsibility to periodically 
        review these Terms to stay informed of updates.
      </p>

      <h2>1. Use of Services</h2>
      <p>
        To access and use the Services, you agree to provide true, accurate, and complete information during and after registration, and you shall 
        be responsible for all acts done through the use of your registered account.
      </p>

      <h2>2. No Warranties or Guarantees</h2>
      <p>
        Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability 
        of the information and materials offered on this website or through the Services for any specific purpose. You acknowledge that such 
        information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to 
        the fullest extent permitted by law.
      </p>

      <h2>3. Risk and Responsibility</h2>
      <p>
        Your use of our Services and the website is solely at your own risk and discretion. You are required to independently assess and ensure 
        that the Services meet your requirements.
      </p>

      <h2>4. Intellectual Property</h2>
      <p>
        The contents of the website and Services are proprietary to us, and you will not have any authority to claim any intellectual property 
        rights, title, or interest in its contents.
      </p>

      <h2>5. Unauthorized Use</h2>
      <p>
        You acknowledge that unauthorized use of the website or the Services may lead to action against you as per these Terms or applicable laws.
      </p>

      <h2>6. Payment and Refunds</h2>
      <p>
        You agree to pay us the charges associated with availing the Services. Refunds are allowed only if we fail to provide the Services as 
        promised. The timelines for return and refund will be governed by specific Service policies. Failure to raise a refund claim within the 
        stipulated time will make you ineligible for a refund.
      </p>

      <h2>7. Prohibited Usage</h2>
      <p>
        You agree not to use the website and/or Services for any purpose that is unlawful, illegal, or forbidden by these Terms, Indian law, 
        or local laws applicable to you.
      </p>

      <h2>8. Third-Party Links</h2>
      <p>
        You agree and acknowledge that the website and the Services may contain links to other third-party websites. On accessing these links, 
        you will be governed by the terms of use, privacy policy, and other policies of such third-party websites.
      </p>

      <h2>9. Binding Contract</h2>
      <p>
        You understand that upon initiating a transaction for availing the Services, you are entering into a legally binding and enforceable 
        contract with us for the Services.
      </p>

      <h2>10. Force Majeure</h2>
      <p>
        Notwithstanding anything contained in these Terms, the parties shall not be liable for any failure to perform an obligation under these 
        Terms if performance is prevented or delayed by a force majeure event.
      </p>

      <h2>11. Governing Law and Jurisdiction</h2>
      <p>
        These Terms and any dispute or claim relating to them, or their enforceability, shall be governed by and construed in accordance with 
        the laws of India. All disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the 
        courts in ADAIYA KARUNGULAM, Tamil Nadu.
      </p>

      <h2>12. Contact Information</h2>
      <p>
        All concerns or communications relating to these Terms must be communicated to us using the contact information provided on this website.
      </p>
    </div>
  );
};

export default TermsAndConditions;
