import React from 'react';
import '../styles/PrivacyPolicy.css';
import MetaTags from '../components/MetaTags';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <MetaTags
        title={`Privacy Policy - Placement Master`}
        description={` Welcome to Placement Master, the most trusted website for placements. We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, and share your information when you visit our website and use our services to purchase digital products.`}
        images={`https://placementmaster.in/assets/img/logo.png`}
        url={`https://shop.onlinedevtools.in/privacy-policy`}
      />
      <h1>Privacy Policy</h1>
      <p>
        Welcome to Placement Master, the most trusted website for placements. We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, and share your information when you visit our website and use our services to purchase digital products.
      </p>

      <h2>1. Information We Collect</h2>
      <p>We collect information that you voluntarily provide to us when you register on the website, place an order, or communicate with us. This information may include:</p>
      <ul>
        <li>Personal Identification Data (e.g., name, email address, phone number)</li>
        <li>Billing Information (e.g., credit card details, billing address)</li>
        <li>Account Information (e.g., username, password, purchase history)</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use your personal information to provide, maintain, and improve our services, including:</p>
      <ul>
        <li>Processing your transactions and delivering digital products</li>
        <li>Sending you order confirmations and updates</li>
        <li>Providing customer support and responding to your inquiries</li>
        <li>Personalizing your experience on our website</li>
        <li>Sending you promotional offers and updates (with your consent)</li>
      </ul>

      <h2>3. How We Share Your Information</h2>
      <p>We do not sell, trade, or otherwise transfer your personal information to outside parties. However, we may share your information with third parties in the following situations:</p>
      <ul>
        <li>With service providers who assist us in operating our website and conducting our business</li>
        <li>To comply with legal obligations or respond to lawful requests from public authorities</li>
        <li>In connection with a merger, sale, or transfer of assets involving Placement Master</li>
      </ul>

      <h2>4. Data Security</h2>
      <p>We implement a variety of security measures to protect your personal information. All transactions are processed through a secure payment gateway, and your payment information is never stored on our servers.</p>

      <h2>5. Cookies and Tracking Technologies</h2>
      <p>We use cookies and similar tracking technologies to enhance your browsing experience and improve our services. You can choose to disable cookies through your browser settings, but this may affect the functionality of our website.</p>

      <h2>6. Your Rights</h2>
      <p>You have the right to access, update, or delete your personal information at any time. You may also opt-out of receiving marketing communications from us. To exercise these rights, please contact us at support@placementmaster.in.</p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any changes will be posted on this page with an updated revision date.</p>

      <h2>8. Contact Us</h2>
      <p>If you have any questions or concerns about our Privacy Policy, please contact us at:</p>
      <p>Email: <a href="mailto:support@placementmaster.in">support@placementmaster.in</a></p>
      <p>Placement Master</p>
    </div>
  );
};

export default PrivacyPolicy;
